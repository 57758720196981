import {oc} from "ts-optchain";
import {cookies} from "./cookies";

export class CsrfTokenFinder {
    readonly _document: Document;

    constructor(_document = document) {
        this._document = _document;
    }

    getCsrfToken = () => this.csrfTokenFromCookie() || this.csrfTokenFromPage();

    csrfTokenFromCookie = () => {
        try {
            return cookies(this._document)["ampyx_csrf_token"];
        } catch (e) {
            console.log("Error getting CSRF token from cookie:", e);
        }
    };

    csrfTokenFromPage = () => {
        try {
            const element = this._document.getElementsByName("csrf-token")[0] as HTMLMetaElement;
            return oc(element).content();
        } catch (e) {
            console.log("Error getting CSRF token from page:", e);
        }
    };
}

export const railsCsrfToken = () => new CsrfTokenFinder().getCsrfToken();
